import { MoveUpRight } from 'lucide-react';

import { Heading } from '@/components/heading';
import { Link } from '@/components/link';
import { useUsage } from '@/hooks/use-usage';
import { getUpgradeAccountUrl } from '@/utils/helpers/urls';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { UsageBarChart } from './bar-chart';

interface Props {
  showUpgrade: boolean;
}

const UserUsageChart = ({ showUpgrade }: Props) => {
  const { usage } = useUsage();

  const handleGoToUpgrade = () => {
    trackUser.event('Go to: Upgrade Account', {
      location: 'User Dropdown',
    });
  };

  const { base, header, legend, chart, chartLabel, chartLabelStat, charts, upgradeLink } = styles();

  return (
    <div className={base()}>
      <div className={header()}>
        <Heading variant="h5" className={legend()} as="h2">
          Account usage
        </Heading>
        {showUpgrade && (
          <div className="flex items-center gap-1">
            <Link className={upgradeLink()} href={getUpgradeAccountUrl()} onClick={handleGoToUpgrade}>
              Upgrade
            </Link>
            <MoveUpRight className="size-2.5" />
          </div>
        )}
      </div>
      <div className={charts()}>
        <div className={chart()}>
          <UsageBarChart value={usage?.routeSearches.usage || 0} total={usage?.routeSearches.limit || 0} />
          <Heading variant="h3" className={chartLabel()}>
            Routes
          </Heading>
          <div className={chartLabelStat()}>
            {usage?.routeSearches.usage || 0} / {usage?.routeSearches.limit || 0}
          </div>
        </div>
        <div className={chart()}>
          <UsageBarChart value={usage?.scheduleSearches.usage || 0} total={usage?.scheduleSearches.limit || 0} />
          <Heading variant="h3" className={chartLabel()}>
            Schedules
          </Heading>
          <div className={chartLabelStat()}>
            {usage?.scheduleSearches.usage || 0} / {usage?.scheduleSearches.limit || 0}
          </div>
        </div>
        <div className={chart()}>
          <UsageBarChart value={usage?.trackedItems?.usage || 0} total={usage?.trackedItems?.limit || 0} />
          <Heading variant="h3" className={chartLabel()}>
            Shipments
          </Heading>
          <div className={chartLabelStat()}>
            {usage?.trackedItems?.usage || 0} / {usage?.trackedItems?.limit || 0}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'flex min-w-36 flex-col gap-2',
    header: 'mb-0.5 flex items-center justify-between',
    legend: 'm-0 font-semibold',
    chart: 'group',
    charts: 'relative grid grid-cols-3 gap-3',
    chartLabel: 'mb-0 mt-0.5 whitespace-nowrap text-xs text-grey-500 group-hover:hidden',
    chartLabelStat: 'mt-0.5 hidden whitespace-nowrap text-xs font-medium text-text-primary group-hover:block',
    upgradeLink: 'text-xs underline',
  },
});

export { UserUsageChart };
