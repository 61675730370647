import { tv } from '@/utils/styles';

interface Props {
  value: number;
  total: number;
}

const UsageBarChart = ({ value = 0, total = 1 }: Props) => {
  const percent = total ? Math.round((value / total) * 100) : 0;
  const cappedPercent = percent < 100 ? percent : 100;
  const isFull = percent > 95;
  const isError = percent > 85;
  const isWarn = percent > 70 && percent < 85;
  const isMinWidth = percent > 0;

  const { wrapper, bar } = styles({ isFull, isMinWidth, isError, isWarn });

  return (
    <div className={wrapper()}>
      <div className={bar()} style={{ width: `${cappedPercent}%` }} />
    </div>
  );
};

const styles = tv({
  slots: {
    wrapper: 'relative h-2 w-full rounded-[4px] bg-black/10',
    bar: 'absolute left-0 h-full rounded-l-[4px] bg-brandDarkBlue transition-all',
  },
  variants: {
    isFull: {
      true: {
        bar: 'rounded-r-[4px]',
      },
      false: null,
    },
    isMinWidth: {
      true: {
        bar: 'min-w-2',
      },
      false: null,
    },
    isError: {
      true: {
        bar: 'bg-red-600',
      },
      false: null,
    },
    isWarn: {
      true: {
        bar: 'bg-yellowDark',
      },
      false: null,
    },
  },
});

export { UsageBarChart };
