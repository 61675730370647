import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { ArrowUpRight, Power } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
import React from 'react';

import { Heading } from '@/components/heading';
import { Link } from '@/components/link';
import { getAvailableNavActions } from '@/components/modules/user/utils';
import { UserDetails } from '@/components/ui/user-details';
import { useUserSettings } from '@/hooks/use-user-settings';
import { getUpgradeAccountUrl } from '@/utils/helpers/urls';
import { getActiveUserOrgs, getUserIsOnFreeTier } from '@/utils/permissions/helpers';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { UserUsageChart } from '../usage-chart';

interface Props {
  session: Session;
  isOpen: boolean;
  showUpgrade: boolean;
  hideUsageOnSmallScreens?: boolean;
}

const UserDropdown = ({ isOpen, session, showUpgrade, hideUsageOnSmallScreens }: Props) => {
  const router = useRouter();
  const { user, subscription } = session;
  const activeUserOrgs = getActiveUserOrgs(user);
  const actionLinks = getAvailableNavActions(session);
  const userIsOnFreeTier = getUserIsOnFreeTier(session?.subscription);
  const { updateUserSettings, isLoading: userSettingsIsUpdating } = useUserSettings();

  const handleSignOut = () => {
    signOut({ callbackUrl: '/' });
    // Reset identity tracking
    trackUser.resetUserIdentity();

    trackUser.event('Sign Out', {
      location: 'Nav',
    });
  };

  const handleOrgChange = (orgId: string) => {
    if (orgId === subscription.orgId) {
      // Is same org, navigate user to settings page
      router.push(getUpgradeAccountUrl());
    } else {
      // Is different org
      changeUserOrg(orgId);
    }
  };

  const handleGoToUpgrade = () => {
    trackUser.event('Go to: Upgrade Account', {
      location: 'User Dropdown',
    });
  };

  const changeUserOrg = async (orgId: string) => {
    trackUser.event('Change Org', {
      location: 'Nav',
    });

    // Update user settings
    const updated = await updateUserSettings({
      currentOrg: {
        value: orgId,
      },
    });

    if (updated) {
      // Force a page refresh on success
      window.location.reload();
    }
  };

  const { divider, nav, navButton, navTitle, badge, base, usageWrapper, buttonIcon } = styles({
    hideUsageOnSmallScreens,
  });

  return (
    <motion.div
      className={base()}
      initial="hidden"
      exit="hidden"
      animate={isOpen ? 'open' : 'hidden'}
      variants={{
        open: { opacity: 1, scale: 1, transition: { duration: 0.3, ease: 'easeInOut' } },
        hidden: { opacity: 0, scale: 0.96 },
      }}
    >
      {user && <UserDetails user={user} />}
      {(showUpgrade || activeUserOrgs?.length) && (
        <div>
          <Heading variant="h5" className={navTitle()}>
            Workspace
          </Heading>
          <ul className={nav()}>
            {subscription &&
              !isEmpty(activeUserOrgs) &&
              activeUserOrgs?.map((org) => {
                const isActive = org.id === subscription.orgId;
                return (
                  <li key={org.id}>
                    <button
                      type="button"
                      className={navButton({ isActive })}
                      onClick={() => handleOrgChange(org.id || '')}
                      disabled={userSettingsIsUpdating}
                    >
                      {org.name}
                      <span className={badge({ isActive })}>{org.subscription?.planName}</span>
                    </button>
                  </li>
                );
              })}
            <li className={usageWrapper()}>
              <UserUsageChart showUpgrade={!userIsOnFreeTier} />
            </li>
            {showUpgrade && userIsOnFreeTier && (
              <li>
                <Link
                  className={navButton({
                    className: '-mb-2 mt-2 justify-between bg-yellowDark px-4 hover:bg-yellowDark hover:underline',
                  })}
                  href={getUpgradeAccountUrl()}
                  onClick={handleGoToUpgrade}
                  passHref
                  prefetch={false}
                >
                  <span>
                    <b>Free Plan</b> - Upgrade now
                  </span>
                  <ArrowUpRight size={16} />
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
      <div>
        {Object.values(actionLinks).map((actions, i) => (
          <React.Fragment key={`action-group-${i}`}>
            <ul className={nav()}>
              {actions.map((action, j) => (
                <li key={`action-${j}`}>
                  <Link className={navButton()} href={action.href}>
                    <span className={buttonIcon()}>{action.icon}</span>
                    {action.label}
                  </Link>
                </li>
              ))}
            </ul>
            {actions.length !== 0 && <div className={divider()} />}
          </React.Fragment>
        ))}
        <ul>
          <li>
            <button className={navButton()} type="button" onClick={() => handleSignOut()}>
              <span className={buttonIcon()}>
                <Power size={16} />
              </span>
              Sign out
            </button>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

const styles = tv({
  slots: {
    base: 'grid min-w-72 max-w-xs origin-[var(--radix-popover-content-transform-origin)] gap-4 rounded-default bg-grey-100 p-4 text-grey-800 shadow-md md:w-auto md:bg-white',
    divider: 'my-1 h-[1px] w-full bg-grey-200',
    nav: 'grid gap-1 text-baseSm',
    navButton:
      'flex w-full items-center rounded-md p-2 text-left text-baseSm no-underline transition-colors duration-200 ease-in-out hover:bg-grey-100 disabled:opacity-50 [&_svg]:mr-1',
    navTitle: 'mb-1 pb-0.5 text-sm font-medium uppercase text-grey-500',
    badge: 'ml-auto inline-block rounded-default bg-grey-100 px-1 text-sm font-medium leading-5 text-grey-500',
    usageWrapper: '-mb-2 mt-2 flex flex-col gap-2 rounded-default bg-grey-100 px-2 py-3',
    buttonIcon: 'mr-1 grid place-items-center',
  },
  variants: {
    isActive: {
      true: {
        navButton: 'bg-grey-100',
        badge: 'bg-yellowDark text-text-primary',
      },
    },
    hideUsageOnSmallScreens: {
      true: {
        usageWrapper: 'hidden',
      },
    },
  },
});

export { UserDropdown };
